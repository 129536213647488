import styled from "styled-components";

const StyledErrorContainer = styled.div`
  height: 70vh;
  background-color: ${({ theme }) => theme.background};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledErrorMessage = styled.h1`
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
`;

export default function custom404() {
  return (
    <StyledErrorContainer>
      <StyledErrorMessage>Sorry, page not found</StyledErrorMessage>
    </StyledErrorContainer>
  );
}
